
.custom-accordion-toggle{
  /* color: rgb(17, 102, 187);
  background-color: gray;
  border-radius: 8px;
  text-decoration: none solid rgb(17, 102, 187);
  text-rendering: optimizelegibility;
  text-size-adjust: 100%;
  text-overflow: ellipsis; */
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  display: block;
  height: 14px;
  text-align: left;
  text-decoration: none solid rgb(0, 0, 0);
  text-rendering: optimizelegibility;
  text-size-adjust: 100%;
  text-overflow: ellipsis;
  /* width: 826px; */
  column-rule-color: rgb(17, 102, 187);
  perspective-origin: 458px 24.5px;
  transform-origin: 458px 24.5px;
  caret-color: rgb(17, 102, 187);
  border: 0px none rgb(17, 102, 187);
  font: normal normal 700 normal 14px / 16.1px Ember;
  outline: rgb(17, 102, 187) none 0px;
  overflow: hidden;
  padding: 17.5px 45px;
}
.custom-accordion-toggle:hover {

  color: rgb(0, 0, 0);
  background-color: rgb(239, 239, 239);
  text-align: left;
  text-decoration: none solid rgb(0, 0, 0);
  text-rendering: optimizelegibility;
  text-size-adjust: 100%;
  column-rule-color: rgb(17, 102, 187);
  caret-color: rgb(17, 102, 187);
  border: 0px none rgb(17, 102, 187);
  font: normal normal 700 normal 14px / 16.1px Ember;
  outline: rgb(17, 102, 187) none 0px;

}

.custom-card-header{
  background-color: white;
}
.custom-card-header:hover{
  background-color: rgb(239, 239, 239);
}

.custom-button{
  color: white;
  background-color: green;
  border-radius: 8px;
}
.custom-nav-bg {
  background-color: #88ff66;
}

/* .form-inline {
  width: 100%;
} */

/* .form-group {
  width: 90%;
} */

/* .input-group {
  width: 90% !important;
} */
.form-control {
  width: 98% !important;
}
.form-label {
  width: 1% !important;
}

.modal-5w {
  width: 4%;
}
/* span.input-group-addon {
  width: 50px !important;
} */

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
